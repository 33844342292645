<template>
    <s-form>
    <div class="row">

        <div class="pt-2 d-sm-none col-12">
            <p class="font-inter-semi-bold">Filters</p>
        </div>

        <validated-select class="ml-0 c-input-select-b col-lg-3 order-2 order-lg-1" placeholder="Customer Type"
                          v-model="filter.customer_type" :options="customerTypeOptions"/>

        <validated-select class="c-input-select-b col-lg-3 order-4 order-lg-2"
                          @input="setDistrict(filter.state)"
                          placeholder="State" v-model="filter.state" :options="stateOptions"/>

        <validated-select class="c-input-select-b col-lg-3 order-4 order-lg-2" placeholder="District"
                          v-model="filter.district" :options="districtOptions"/>

        <validated-input class="order-1 order-lg-3 col-lg-3" placeholder="Search Bidder Name"
                         v-model="filter.all_search">
            <template #append>
                <i class="fa fa-search"></i>
            </template>
        </validated-input>

    </div>
        </s-form>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name : 'BiddersListFilter',

    props : {
        model : {
            type : Object,
            default () {
                return {
                    state         : '',
                    customer_type : '',
                    district      : '',
                    all_search    : ''
                };
            }
        }
    },

    data () {
        return {
            stateOptions        : [],
            districtOptions     : [],
            AllDistrictOptions  : [],
            customerTypeOptions : [
                { label : 'All', value : 'All' },
                { label : 'Individual', value : 'Individual' },
                { label : 'Group Individual', value : 'Group Individual' },
                { label : 'Proprietor', value : 'Proprietor' },
                { label : 'Partnership', value : 'Partnership' },
                { label : 'LLP', value : 'LLP' },
                { label : 'Private Limited / Limited', value : 'Private Limited / Limited' },
                { label : 'Society / Trust', value : 'Society / Trust' }
            ]
        };
    },

    computed : {
        filter () {
            return this.model;
        }
    },

    beforeMount () {
        this.loadStates();
        this.loadDistricts();
    },

    methods : {
        async loadStates () {
            const response = await axios.get(urls.auction.auctionList.stateVueSelect + '?type=Text');
            this.stateOptions = response.data.data;
            this.stateOptions.unshift({ label : 'All', value : 'All' });
        },

        async loadDistricts () {
            const response = await axios.get(urls.auction.auctionList.districtVueSelect + '?type=Text');
            this.AllDistrictOptions = response.data.data;
            this.districtOptions = response.data.data;
            this.districtOptions.unshift({ label : 'All', value : 'All', state : '' });
        },

        setDistrict (state) {
            if (state === 'All') {
                this.filter.district = '';
                this.districtOptions = [...this.AllDistrictOptions];
            } else {
                const filterValues = this.AllDistrictOptions.filter(ele => ele.state === state);
                this.filter.district = '';
                if (filterValues.length > 0) {
                    filterValues.unshift({ label : 'All', value : 'All', state : '' });
                }
                this.districtOptions = [...filterValues];
            }
        }
    }
};
</script>

<style scoped lang="scss">
//.filter-grid {
//    display: grid;
//    width: 100%;
//    grid-template-columns: 1fr 1fr;
//    @media(min-width: 992px) {
//        grid-template-columns: 0.5fr repeat(2, 2fr) 3fr 2fr;
//    }
//}
</style>
