<template>
    <inner-page-card heading="Bidders List" class="mt-3">
        <div>
            <bidders-list-filter :model="filter"></bidders-list-filter>
        </div>

        <custom-vue-table class="d-sm-none" :show-search-box="false" :fields="fields"
                          :url="listUrl" :per-page="10" ref="table" :extra-params="{
                       district: filter.district,
                       state: filter.state,
                       customer_type: filter.customer_type,
                    all_search: filter.all_search
                   }">

            <template slot="actions" slot-scope="props">
                <div class="btn-group">
                    <btn v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.is_ventura_admin
                         || currentUser.permission.can_view_bidder)" icon="fa fa-eye" size="sm" design=""
                         class="border-gray-hover p-2 text-5"
                         @click="setView(props.rowData)"></btn>
<!--                    <btn design="" icon="fa fa-trash-o" class="border-gray-hover p-2 text-5"-->
<!--                         v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.is_ventura_admin-->
<!--                         || currentUser.permission.can_delete_bidder) && props.rowData.status === ''"></btn>-->
                </div>
            </template>

        </custom-vue-table>

        <bidders-list-mobile-view class="d-lg-none"
                                  :all_search="filter.all_search"
                                  :district="filter.district"
                                  :state="filter.state"
                                  :customer_type="filter.customer_type"/>

    </inner-page-card>
</template>

<script>
import BiddersListFilter from '../../views/bidders/BiddersListFilter';
import BiddersListMobileView from '../../views/bidders/BiddersListMobileView';
import urls from '../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'BiddersList',

    components : { BiddersListMobileView, BiddersListFilter },

    data () {
        return {
            listUrl : urls.userList.biddersList.List,

            filter : {
                district      : '',
                state         : '',
                customer_type : '',
                all_search    : ''
            },

            editingItem : null,

            fields : [
                {
                    name  : 'sl_no',
                    title : 'SL NO'
                },
                {
                    name  : 'participant_id',
                    title : 'Bidder ID'
                },
                {
                    name  : 'name',
                    title : 'Name'
                },
                {
                    name  : 'company',
                    title : 'Company Name'
                },
                {
                    name  : 'email',
                    title : 'Email'
                },
                {
                    name  : 'mobile',
                    title : 'Phone'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ]
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    methods : {
        setView (item) {
            this.$router.push('/bidders/' + item.id + '/details/');
        },

        formSuccess () {
            this.$refs.UserModal.close();
            this.editingItem = null;
        }
    }
};
</script>

<style scoped>

</style>
