var render = function render(){var _vm=this,_c=_vm._self._c;return _c('inner-page-card',{staticClass:"mt-3",attrs:{"heading":"Bidders List"}},[_c('div',[_c('bidders-list-filter',{attrs:{"model":_vm.filter}})],1),_c('custom-vue-table',{ref:"table",staticClass:"d-sm-none",attrs:{"show-search-box":false,"fields":_vm.fields,"url":_vm.listUrl,"per-page":10,"extra-params":{
                       district: _vm.filter.district,
                       state: _vm.filter.state,
                       customer_type: _vm.filter.customer_type,
                    all_search: _vm.filter.all_search
                   }},scopedSlots:_vm._u([{key:"actions",fn:function(props){return [_c('div',{staticClass:"btn-group"},[((_vm.currentUser.is_superuser || _vm.currentUser.is_bank_admin || _vm.currentUser.is_ventura_admin
                         || _vm.currentUser.permission.can_view_bidder))?_c('btn',{staticClass:"border-gray-hover p-2 text-5",attrs:{"icon":"fa fa-eye","size":"sm","design":""},on:{"click":function($event){return _vm.setView(props.rowData)}}}):_vm._e()],1)]}}])}),_c('bidders-list-mobile-view',{staticClass:"d-lg-none",attrs:{"all_search":_vm.filter.all_search,"district":_vm.filter.district,"state":_vm.filter.state,"customer_type":_vm.filter.customer_type}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }