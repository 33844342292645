<template>

    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-4">

                <div class="gr-3-cols">

                    <div class="fl-gr-1 b-1 py-2">
                        <div class="fl-x fl-j-c fl-a-c">
                            <div class="pos-r pl-4 pr-4">
                                <div class=""></div>
                                <span class="font-inter-medium">Sl No</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1 py-2">
                        <div class="fl-x fl-j-s fl-a-c">
                            <div class="pos-r pl-4 pr-4">
                                <div class=""></div>
                                <span class="font-inter-medium">Bidder ID</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1 py-2">
                        <div class="fl-x fl-j-s fl-a-c">
                            <div class="pos-r pl-4  pr-4">
                                <div class=""></div>
                                <span class="font-inter-medium">Name</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="b-1 bs-3">
                    <div class="gr-3-cols font-inter-medium c-line-bottom">

                        <div class="py-2 b-1">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r fl-x-bc">
                                    <div class=""></div>
                                    {{ i.sl_no }}
                                </div>
                            </div>
                        </div>

                        <div class="b-1">
                            <div class="pos-r fl-x-bc ">
                                <div class=""></div>
                                {{ i.participant_id }}
                            </div>
                        </div>

                        <div class="b-1">
                            <div class="pos-r  fl-x-bc">
                                <div class=""></div>
                                {{ i.name }}
                            </div>
                        </div>

                    </div>

                    <div class="row b-1 fs--1 m-0">
                <span class="b-1 w-100 col-6">
                    <div class="p-1">
                       <span class="font-inter-medium">Company Name</span> <span class="ml-2">{{ i.company }}</span>
                    </div>
                </span>
                        <span class="b-1 w-100 col-6">
                    <div class="p-1">
                       <span class="font-inter-medium">Phone</span> <span class="ml-2">{{ i.mobile }}</span>
                    </div>
                </span>
                        <span class="b-1 w-100 col-12">
                    <div class="p-1">
                       <span class="font-inter-medium">Email&nbsp;:&nbsp;</span> <span
                        class="ml-lg-2 d-inline-block ml-0 d-inline-block">{{ i.email }}</span>
                    </div>
                </span>

                        <span class="b-1 w-100 col-12 fl-x-cc">
                    <div class="btn-group">

                        <btn v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.is_ventura_admin
                         || currentUser.permission.can_view_bidder)" icon="fa fa-eye" size="sm" design=""
                             class="border-gray-hover p-2 text-5"
                             @click="setView(i)"/>

<!--                    <btn design="" icon="fa fa-trash-o" class="border-gray-hover p-2 text-5"-->
<!--                         v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.is_ventura_admin-->
<!--                         || currentUser.permission.can_delete_bidder) && i.status === ''"/>-->
                </div>
                </span>
                    </div>

                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
// import ActivateSuspendButtons from '@components/manage-users/ui/ActivateSuspendButtons';
import { mapGetters } from 'vuex';
import urls from '../../data/urls';
import axios from 'secure-axios';
import _ from 'lodash';

export default {
    name : 'BiddersListMobileView',

    props : {
        district : {
            type : String
        },
        state : {
            type : String
        },
        customer_type : {
            type : String
        },
        all_search : {
            type : String
        }
    },

    watch : {
        district () {
            this.timeOutLoadData();
        },

        state () {
            this.timeOutLoadData();
        },

        customer_type () {
            this.timeOutLoadData();
        },

        all_search () {
            this.timeOutLoadData();
        }

    },

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '5',
            loading  : true,
            error    : false,
            details  : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        timeOutLoadData : _.debounce(function () {
            this.loadData();
        }, 2000),

        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.userList.biddersList.List, {
                params : {
                    page          : that.page,
                    per_page      : that.per_page,
                    district      : that.district,
                    state         : that.state,
                    customer_type : that.customer_type,
                    all_search    : that.all_search
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.setPagination();
                that.loading = false;
                that.error = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },

        toggleUserActiveStatus (i) {
            this.items[i].active = !this.items[i].active;
        },

        setView (item) {
            this.$router.push('/bidders/' + item.id + '/details/');
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        }
    }
};
</script>

<style scoped lang="scss">
.gr-4-cols {
    display: grid;
    grid-template-columns:repeat(4, 1fr);
}

.gr-3-cols {
    display: grid;
    grid-template-columns:repeat(3, 1fr);
}

.c-line-bottom {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: rgba(128, 128, 128, 0.3);
    }
}

.icon-danger {
    i {
        color: var(--color-danger) !important;
    }
}

.icon-primary {
    i {
        color: var(--color-primary) !important;
    }
}
</style>
